<template>
    <div class="app-account-box" :class="{ opened }">
        <div class="app-account">
            <div class="toggle" @click="toggle">
                <div class="account">
                    <i></i>
                    <span>{{ auth_id_display_name }}</span>
                </div>
                <div class="arrow-down"></div>
            </div>

            <div class="body">
                <div class="info">
                    <div class="email"><span>{{ auth_id_display_name }}</span></div>
                    <div class="test">SPID: {{ current_spid }}</div>
                    <div class="test" v-if="current_sp_name">Name: {{ current_sp_name }}</div>
                </div>

                <div class="actions" @click="close">
                    <router-link class="super-admin" :to="{ name: 'admin' }" v-if="is_super_admin"><i></i>Super Admin</router-link>
                    <router-link class="settings" :to="{ name: 'settings' }" v-if="is_super_admin || is_sp_admin"><i></i>Settings</router-link>
                    <button      class="change-password" @click="changePassword" :disabled="processing"><i></i>Change password</button>
                    <button      class="providers"       @click="selectProvider" :disabled="processing" v-if="select_provider_avaliable"><i></i>Select Provider</button>

                    <button      class="sign-out"  @click="signOut"        :disabled="processing"><i></i>Sign out</button>
                </div>
            </div>
        </div>

        <dialog-change-password
            v-if="dialogs.change_password.show"
            v-model="dialogs.change_password.show"

            @close="onDialogChangePasswordClose"
        />

        <dialog-select-provider
            v-if="dialogs.select_provider.show"
            v-model="dialogs.select_provider.show"
        ></dialog-select-provider>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import dialogSelectProvider from './dialogs/dialog-select-provider'
import dialogChangePassword from './dialogs/dialog-change-password'

export default {
    components: {
        dialogSelectProvider,
        dialogChangePassword,
    },

    data() {
        return {
            opened: false,

            dialogs: {
                select_provider: {
                    show: false,
                },

                change_password: {
                    show: false,
                },
            },

            processing: false,
        }
    },

    methods: {
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.close()
            }
        },

        open() {
            this.opened = true
            document.addEventListener('click', this.handleClickOutside)
        },

        close() {
            this.opened = false
            document.removeEventListener('click', this.handleClickOutside)
        },

        toggle() {
            if (this.opened) {
                this.close()
            } else {
                this.open()
            }
        },

        signOut() {
            this.processing = true
            this.$store.dispatch('api_auth/AuthLogout', { SPID: this.current_spid, JWT: this.jwt }).then(() => {
                this.$store.dispatch('Logout').then(() => {
                    this.processing = false
                    this.$router.push({ name: 'sign-in'})
                })
            })
            .catch(() => {
                this.$store.dispatch('Logout').then(() => {
                    this.processing = false
                    this.$router.push({ name: 'sign-in'})
                })
            })
            
            this.close()
        },

        selectProvider() {
            this.dialogs.select_provider.show = true

            this.close()
        },

        changePassword() {
            this.dialogs.change_password.show = true

            this.close()
        },

        onDialogChangePasswordClose() {
            this.dialogs.change_password.show = false
        },
    },

    computed: {
        ...mapGetters({
            'auth_id_display_name': 'id_display_name',
        }),

        ...mapGetters([
            'jwt',
            'current_sp',
            'current_spid',
            'modules_user',

            'is_sp_admin',
            'is_super_admin',
            'current_sp_roles',
            'available_service_providers',
        ]),
        
        roles_list() {
            return this.current_sp_roles.map(role => role.toLowerCase().replace(/[^\w]|_/g, ' ').replace(/^./, role[0].toUpperCase())).join(', ')
        },

        current_sp_name() {
            return this.current_sp && this.current_sp.Name
        },

        select_provider_avaliable() {
            return this.available_service_providers.length > 1;
        },
    },
}
</script>

<style lang="scss">
.app-account-box {
    position: relative;

    .app-account {
        position: relative;

        z-index: $z-index-dropdown;

        background-color: var(--color-account-bg);
        border-radius: $border-radius-primary;

        i {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            color: var(--color-icon-account);
        }
    }


    .toggle {
        display: flex;
        justify-content: space-between;
        padding: 12px 24px;
        cursor: pointer;
        user-select: none;

        .account {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 42px 0 0;
            font-size: 18px;
            line-height: 24px;
            color: var(--color-text-default);
            overflow: hidden;

            i {
                @include icon-before($icon-account);
            }

            span {
                @include text-overflow();
            }
        }

        .arrow-down {
            flex-shrink: 0;
            width: 26px;
            height: 26px;

            color: var(--color-icon-account);

            @include icon-before($icon-chevron-bottom, 26px);
        }
    }

    .body {
        display: none;
        width: 100%;
        padding: 0 24px;
        background: inherit;
        border-radius: 0 0 $border-radius-primary $border-radius-primary;
        position: absolute;
        top: 100%;
        right: 0;

        .info {
            padding-top: 12px;
            padding-bottom: 12px;

            .test {
                opacity: .4;
            }

            .email {
                display: none;
            }

            .roles {
                font-size: 14px;
                font-weight: 300;
                line-height: 1.5;
                color: var(--color-text-default);
            }
        }

        .actions {
            padding: 12px 0;
            border-top: 1px solid var(--color-divider);

            a, button {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: fit-content;
                padding-right: 8px + 16px;
                margin: 6px 0;
                font-size: 18px;
                line-height: 24px;
                color: var(--color-text-default);
                white-space: nowrap;
                cursor: pointer;

                &:first-child { margin-top: 0; }
                &:last-child { margin-bottom: 0; }

                &:hover {
                    opacity: .8;
                }

                &:active {
                    opacity: .9;
                }

                &.super-admin {
                    i {
                        @include icon-before($icon-super-admin);
                    }
                }

                &.settings {
                    i {
                        @include icon-before($icon-settings);
                    }
                }

                &.change-password {
                    i {
                        @include icon-before($icon-change-password);
                    }
                }

                &.providers {
                    i {
                        @include icon-before($icon-select-provider);
                    }
                }

                &.sign-out {
                    i {
                        color: var(--color-icon-account-sign-out);

                        @include icon-before($icon-sign-out);
                    }
                }
            }
        }
    }

    &.opened {
        .app-account {
            filter: var(--account-shadow);
            border-radius: $border-radius-primary $border-radius-primary 0 0;
        }

        .toggle {
            .arrow-down {
                transform: rotateX(180deg);
            }
        }

        .body {
            display: block;
        }
    }
}

@media (max-width: $tablet-size) {
    .app-account-box {
        .toggle {
            padding: 12px;

            .account {
                padding-right: 6px;

                span {
                    display: none;
                }
            }
        }

        .body {
            border-top-left-radius: $border-radius-primary;
            .info .email {
                margin-bottom: 4px;
                font-size: 18px;
                line-height: 1.33;
                color: var(--color-text-default);
                display: block;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-account-box {
        .body {
            box-shadow: var(--box-shadow-primary);

            .info {
                .email {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .actions {
                a, button {
                    font-size: 16px;
                }
            }
        }

        &.opened {
            .app-account {
                filter: none;
            }
        }
    }
}
</style>