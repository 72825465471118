<template>
    <div class="app-navigation-bar" :class="{ showed, 'is-android': is_android }">
        <app-logo @close="close" />

        <div class="navigation-box">
            <div v-show="show_top_indicator" class="indicator top" :class="{ 'is-ios-13': is_ios_13 }" @click="scrollTop">
                <div class="bg"></div>
                <i></i>
            </div>

            <div class="scroll" ref="scroll">
                <div class="category" v-for="(category, category_name) in categories" :key="`cat-${ category_name }`">
                    <span class="name" v-if="category.title">{{ category.title }}</span>

                    <div class="menu-box">
                        <div class="menu" v-for="(module, module_name) in category.modules" :key="`cat-${ category_name }-mod-${ module_name }`"
                            :class="[{ opened: isActive(module.class) }, module.class]"
                        >
                            <template v-if="module.link">
                                <router-link :to="module.link.to" class="toggle">
                                    <span class="icon"></span>
                                    <span class="title">{{ module.link.title }}</span>
                                </router-link>
                            </template>

                            <template v-else>
                                <div class="toggle" @click="toggleSubMenu(module.class)">
                                    <span class="icon"></span>
                                    <span class="title">{{ module.title }}</span>
                                </div>

                                <div class="sub-menu">
                                    <router-link v-for="(link, link_index) in module.sub_menu" :key="`cat-${ category_name }-mod-${ module_name }-link-${ link_index }`"
                                        :class="[ link.class ]"
                                        :to="link.to"
                                    >
                                        <div class="icon"><i></i></div>
                                        <div class="link">{{ link.title }}</div>
                                    </router-link>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="show_bottom_indicator" class="indicator bottom" :class="{ 'is-ios-13': is_ios_13 }" @click="scrollBottom">
                <div class="bg"></div>
                <i></i>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store'

import { mapGetters } from 'vuex'

import appLogo from '@/components/app-logo-v2'

import isAndroid from '@/helpers/is-android'

const have_vfenvironment_options = store.getters.have_vfenvironment_options

const CONFIG = {
    PRODUCT: 'Products',
    SERVICE: 'Services',
    TOOL:    'Tools',
}

const MODULES = {
    CUSTOMERS: {
        class: 'customers',

        link: {
            to: { name: 'customers' },
        },

        route_names: [
            'customer-details',
            'customer-products',
            'customer-orders',
        ],
    },

    PROD_FWA: {
        class: 'fwa',

        sub_menu: [
            {
                class: 'add-service',
                to: { name: 'fwa-add-service' },
                title: 'Add FWA Product',
                restrictions: [
                    'has_role_fwa',
                ],
            },
            {
                class: 'orders',
                to: { name: 'fwa-orders' },
                title: 'FWA Orders',
                restrictions: [
                    'has_role_fwa',
                ],
            },
            // {
            //     class: 'products',
            //     to: { name: 'fwa-products' },
            //     title: 'FWA Products',
                // restrictions: [
                //     'has_role_fwa',
                // ],
            // },
            {
                class: 'services',
                to: { name: 'fwa-services' },
                title: 'FWA Services',
                restrictions: [
                    'has_role_fwa',
                ],
            },
        ],
    },

    PROD_FIBRE: {
        class: 'fibre',

        sub_menu: [
            {
                class: 'add-service',
                to: { name: 'fibre-add-service' },
                title: 'Add Fibre',
                restrictions: [
                    'has_role_fibre',
                ],
            },
            {
                class: 'orders',
                to: { name: 'fibre-orders' },
                title: 'Fibre Orders',
                restrictions: [
                    'has_role_fibre',
                ],
            },
            {
                class: 'services',
                to: { name: 'fibre-services' },
                title: 'Fibre Services',
                restrictions: [
                    'has_role_fibre',
                ],
            },
        ],

        route_names: [
            'fibre-order-details',
            'fibre-service-details',

            'fibre-order-notes',
            'fibre-service-notes',

            'fibre-bulk-add-service',
        ],
    },

    PROD_MOBILE: {
        class: 'mobile',

        sub_menu: [
            {
                class: 'add-service',
                to: { name: 'postpay-mobile-add-service' },
                title: 'Add Product',
                restrictions: [
                    'has_role_postpay',
                ],
            },
            {
                class: 'orders',
                to: { name: 'postpay-mobile-orders' },
                title: 'Mobile Orders',
                restrictions: [
                    'has_role_postpay',
                ],
            },

            /*
                {
                    class: 'products',
                    to: { name: 'postpay-mobile-products' },
                    title: 'Mobile Products',
                    // restrictions: [
                    //     'has_role_postpay',
                    // ],
                },
            */

            {
                class: 'services',
                to: { name: 'postpay-mobile-services' },
                title: 'Mobile Services',
                restrictions: [
                    'has_role_postpay',
                ],
            },
        ],
    },

    DOMAINS: {
        class: 'domains',

        sub_menu: [
            {
                class: 'domain-list',
                to: { name: 'domains-domain-list' },
                title: 'Domain List',
                restrictions: [
                    'has_role_domains',
                ],
            },
            {
                class: 'register-domain',
                to: { name: 'domains-register-domain' },
                title: 'Register Domain',
                restrictions: [
                    'has_role_domains',
                ],
            },
            {
                class: 'transfer-domain',
                to: { name: 'domains-transfer-domain' },
                title: 'Transfer Domain',
                restrictions: [
                    'has_role_domains',
                ],
            },
            {
                class: 'default-settings',
                to: { name: 'domains-default-settings' },
                title: 'Default Settings',
                restrictions: [
                    'has_role_domains',
                ],
            },
        ],

        route_names: [
            'domains-register-domain-register',
            'domains-register-domain-bulk-register',

            'domains-register-domain-confirm-registration',

            'domains-transfer-domain-confirm-transfer',
        ],
    },

    SIP_TRUNKING: {
        class: 'sip-trunking',

        sub_menu: [
            {
                class: 'add-service',
                to: { name: 'sip-trunking-add-trunk' },
                title: 'Add SIP Trunk',
                restrictions: [
                    'has_role_sip_trunking',
                ],
            },
            {
                class: 'sip-trunks',
                to: { name: 'sip-trunking-sip-trunks' },
                title: 'SIP Trunks',
                restrictions: [
                    'has_role_sip_trunking',
                ],
            },
            /*
                {
                    class: 'channels',
                    to: { name: 'sip-trunking-channels' },
                    title: 'Channels',
                    restrictions: [
                        'has_role_sip_trunking',
                    ],
                },
            */
            /*
                {
                    class: 'routing',
                    to: { name: 'sip-trunking-routing' },
                    title: 'Routing',
                    restrictions: [
                        'has_role_sip_trunking',
                    ],
                },
            */
            {
                class: 'tools',
                to: { name: 'sip-trunking-tools' },
                title: 'Tools',
                restrictions: [
                    'has_role_sip_trunking',
                ],
            },
        ],

        route_names: [
            'sip-trunking-tools-common-tasks',
            'sip-trunking-tools-advanced',

            'sip-trunking-sip-trunk-create',
            'sip-trunking-sip-trunk-edit',
            'sip-trunking-sip-trunk-bulk-edit-numbers',
            'sip-trunking-sip-trunk-product-notes',

            'sip-trunking-tools-common-tasks-bulk-options',
        ],
    },

    BULK_PRE_QUAL: {
        class: 'bulk-pre-qual',

        link: {
            to: { name: 'bulk' },
        },

        route_names: [
            'bulk-new',
            'bulk-previous',
            'bulk-search',
            'bulk-pre-qual',
            'bulk-previous-searches',
            'bulk-search-details',
        ],
    },

    PREPAY_MOBILE: {
        class: 'prepay-mobile',

        sub_menu: [

            {
                class: 'add-service',
                to: { name: 'prepay-mobile-add-service' },
                title: 'Add Product',
                restrictions: [
                    // 'is_prerelease_mode',
                ],
            },
            {
                class: 'orders',
                to: { name: 'prepay-mobile-orders' },
                title: 'Mobile Orders',
                restrictions: [
                    // 'is_prerelease_mode',
                ],
            },

            /*
                {
                    class: 'products',
                    to: { name: 'prepay-mobile-products' },
                    title: 'Mobile Products',
                    restrictions: [
                        'is_prerelease_mode',
                    ],
                },
            */

            {
                class: 'services',
                to: { name: 'prepay-mobile-services' },
                title: 'Mobile Services',
                restrictions: [
                    // 'is_prerelease_mode',
                ],
            },

            {
                class: 'prepay-mobile-preactivation',
                to: { name: 'prepay-mobile-preactivation' },
                title: 'Preactivation',
                restrictions: [
                    'is_prerelease_mode',
                ],
            },
        ],

        route_names: [
            'prepay-mobile-preactivation-reserve',
            'prepay-mobile-preactivation-previous-reservations',
            'prepay-mobile-preactivation-preactivation',
        ],
    },

    SVC_RADIUS: {
        class: 'radius',

        sub_menu: [
            {
                class: 'services',
                to: { name: 'radius-services' },
                title: 'Radius Services',
                restrictions: [
                    'has_role_radius',
                ],
            },
            {
                class: 'sessions',
                to: { name: 'radius-sessions' },
                title: 'Radius Sessions',
                restrictions: [
                    'has_role_radius',
                ],
            },
            {
                class: 'usage',
                to: { name: 'radius-usage' },
                title: 'Radius Usage',
                restrictions: [
                    'has_role_radius',
                ],
            },
            {
                class: 'config',
                to: { name: 'radius-config' },
                title: 'Radius Config',
                restrictions: [
                    'has_role_radius',
                ],
            },
        ],

        route_names: [
            'radius-service-details',
            'radius-session-details',
        ],
    },

    SVC_TV: {
        class: 'tv',

        sub_menu: [
            {
                class: 'households',
                to: { name: 'households-local' },
                title: 'Households',
                restrictions: [
                    'has_role_tv',
                ],
            },
            {
                class: 'devices',
                to: { name: 'devices-local' },
                title: 'TV Devices',
                restrictions: [
                    'has_role_tv',
                ],
            },
            {
                class: 'entitlements',
                to: { name: 'entitlements' },
                title: 'TV Entitlements',
                restrictions: [
                    'has_role_tv',
                ],
            },
        ],

        route_names: [
            'households-kaltura',
            'devices-kaltura',
            'households-local-details',
            'devices-local-details',
            'households-kaltura-search-results',
            'devices-kaltura-search-results',
        ],
    },

    SVC_NUMBER_PORTING: {
        class: 'number-porting',

        sub_menu: [
            {
                class: 'number-porting-number-ports',
                to: { name: 'number-porting-number-ports' },
                title: 'Number Ports',
                restrictions: [
                    'has_role_number_porting',
                ],
            },
        ],

        route_names: [],
    },

    SVC_MATRIXX: {
        class: 'matrixx',

        sub_menu: [
            {
                class: 'sp-groups',
                to: { name: 'matrixx-sp-groups' },
                title: 'SP Groups',
                restrictions: [
                    'has_role_matrixx',
                ],
            },
            {
                class: 'customer-groups',
                to: { name: 'matrixx-customer-groups' },
                title: 'Customer Groups',
                restrictions: [
                    'has_role_matrixx',
                ],
            },
            {
                class: 'subscriptions',
                to: { name: 'matrixx-subscriptions' },
                title: 'Subscriptions',
                restrictions: [
                    'has_role_matrixx',
                ],
            },
            {
                class: 'devices',
                to: { name: 'matrixx-devices' },
                title: 'Devices',
                restrictions: [
                    'has_role_matrixx',
                ],
            },
        ],

        route_names: [
            'matrixx',
            'matrixx-sp-group-details',
            'matrixx-customer-group-details',
            'matrixx-subscription-details',
            'matrixx-device-details',
        ],
    },

    IP_MANAGER: {
        class: 'ip-manager',

        sub_menu: [
            {
                class: 'query',
                to: { name: 'ip-manager-query' },
                title: 'Query IP',
                restrictions: [
                    'has_role_ip_manager',
                ],
            },
            {
                class: 'allocate',
                to: { name: 'ip-manager-allocate' },
                title: 'Allocate IP',
                restrictions: [
                    'has_role_ip_manager',
                ],
            },
            {
                class: 'vrfs',
                to: { name: 'ip-manager-vrfs' },
                title: 'VRFs',
                restrictions: [
                    'has_role_ip_manager',
                ],
            },
            {
                class: 'pools',
                to: { name: 'ip-manager-pools' },
                title: 'Pools',
                restrictions: [
                    'has_role_ip_manager',
                ],
            },
        ],

        route_names: [
            'ip-manager',
            'ip-manager-query',
            'ip-manager-allocate',
            'ip-manager-vrfs',
            'ip-manager-pools',
        ],
    },

    PSTN_MANAGER: {
        class: 'pstn-manager',

        sub_menu: [
            {
                class: 'pstn-lookup',
                to: { name: 'pstn-manager-lookup' },
                title: 'Lookup Number',
                restrictions: [
                    'has_role_number_manager',
                ],
            },
            {
                class: 'pstn-allocate',
                to: { name: 'pstn-manager-allocate' },
                title: 'Allocate Numbers',
                restrictions: [
                    'has_role_number_manager',
                ],
            },
        ],

        route_names: [
            'pstn-manager',
            'pstn-manager-lookup',
            'pstn-manager-allocate',
        ],
    },

    NUMBER_MANAGER: {
        class: 'number-manager',

        sub_menu: [
            {
                class: 'ranges',
                to: { name: 'number-manager-ranges' },
                title: 'Number Ranges',
                restrictions: [
                    'has_role_number_manager',
                ],
            },
            {
                class: 'sim-ranges',
                to: { name: 'number-manager-sim-ranges' },
                title: 'SIM Ranges',
                restrictions: [
                    'has_role_sim_manager',
                ],
            },
            {
                class: 'allocations',
                to: { name: 'number-manager-allocations' },
                title: 'Number Allocations',
                restrictions: [
                    'has_role_number_manager',
                ],
            },
        ],
    },

    LOYALTY_MANAGEMENT: {
        class: 'loyalty-management',

        sub_menu: [
            {
                class: 'search-voucher',
                to: { name: 'loyalty-management-search-voucher' },
                title: 'Search Voucher',
                restrictions: [
                    'has_role_voucher_admin',
                ],
            },
        ],

        route_names: [
            'loyalty-management-customer-search',
        ],
    },

    SRV_ASAP: {
        class: 'asap',

        sub_menu: [
            ...(have_vfenvironment_options ? [
                {
                    class: 'asap-configuration',
                    to: { name: 'asap-configuration' },
                    title: 'Configuration',
                    restrictions: [
                        "has_role_asap_write",
                    ],
                },
            ] : []),
            {
                class: 'asap-workflows',
                to: { name: 'asap-workflows' },
                title: 'Workflows',
                restrictions: [
                    "has_role_asap_any",
                ],
            },
        ],
        route_names: [
            'asap-configuration-list',
            'asap-configuration-create',
            'asap-configuration-edit',

            'asap-workflow-summary',
            'asap-workflow-history',
        ],
    },

    SERVICE_INVENTORY: {
        class: 'service-inventory',

        link: {
            to: { name: 'service-inventory' },
        },
    },

    SERVICE_ORDERS: {
        class: 'service-orders',

        link: {
            to: { name: 'service-orders' },
        },
    },

    SVC_SMS_ROUTER: {
        class: 'smsc',

        sub_menu: [
            {
                class: 'smsc-entries',
                to: { name: 'smsc-entries' },
                title: 'Entries',
                restrictions: [
                    'is_super_admin',
                ],
            },
        ],

        route_names: [
            'smsc',
            'smsc-entries',
        ],
    },

    DIGITAL_COMMS: {
        class: 'digital-comms',

        sub_menu: [
            {
                class: 'templates',
                to: { name: 'digital-comms-templates' },
                title: 'Templates',
            },
            {
                class: 'configuration',
                to: { name: 'digital-comms-configuration' },
                title: 'Configuration',
            },
        ],

        route_names: [
            'digital-comms',
            'digital-comms-template',
            'digital-comms-template-email',
            'digital-comms-template-sms',
            'digital-comms-template-general',
            'digital-comms-template-advanced',
        ],
    },

    SMS_GATEWAY: {
        class: 'sms-gateway',

        sub_menu: [
            {
                class: 'sms-gateway-send-message',
                to: { name: 'sms-gateway-send-message' },
                title: 'Send Message',
                restrictions: [
                    'is_super_admin',
                ],
            },
            {
                class: 'sms-gateway-queues',
                to: { name: 'sms-gateway-queues' },
                title: 'Queues',
                restrictions: [
                    'has_role_sms_gateway',
                ],
            },
        ],
    },
}

export default {
    components: {
        appLogo,
    },

    props: {
        showed: { type: Boolean, default: false },
    },

    data() {
        return {
            active_sub_menu: null,

            show_top_indicator: false,
            show_bottom_indicator: false,

            is_ios_13: false,
        }
    },

    computed: {
        ...mapGetters([
            'modules_sp',

            'current_spid',
            'is_super_admin',
            'current_sp_roles',
            'is_prerelease_mode',
        ]),

        is_spid_1() {
            return this.current_spid == 1
        },

        has_role_customers() {
            return this.is_super_admin || this.current_sp_roles.includes('READ') || this.current_sp_roles.includes('WRITE')
        },

        has_role_fibre() {
            return this.is_super_admin || this.has_sp_role_fibre
        },

        has_sp_role_fibre() {
            return this.is_super_admin || this.current_sp_roles.includes('PROD_FIBRE') || this.current_sp_roles.includes('FIBRE.READ') || this.current_sp_roles.includes('FIBRE.MANAGE')
        },

        has_role_fwa() {
            return this.is_super_admin || this.has_sp_role_fwa
        },

        has_sp_role_fwa() {
            return this.current_sp_roles.includes('PROD_FWA') || this.current_sp_roles.includes('FWA.READ') || this.current_sp_roles.includes('FWA.MANAGE')
        },

        has_role_postpay() {
            return this.is_super_admin || this.has_sp_role_postpay
        },

        has_sp_role_postpay() {
            return this.current_sp_roles.includes('PROD_MOBILE') || this.current_sp_roles.includes('MOBILE.READ') || this.current_sp_roles.includes('MOBILE.MANAGE')
        },

        has_role_prepay() {
            return this.is_super_admin || this.has_sp_role_prepay
        },

        has_sp_role_prepay() {
            return this.is_super_admin || this.current_sp_roles.includes('PROD_PREPAY') || this.current_sp_roles.includes('PREPAY.READ') || this.current_sp_roles.includes('PREPAY.MANAGE')
        },

        has_role_number_porting() {
            return this.is_super_admin || this.current_sp_roles.includes('SVC_NUMBER_PORTING')
        },

        has_role_ip_manager() {
            return this.is_super_admin || this.current_sp_roles.includes('IP_MANAGER')
        },

        has_role_number_manager() {
            return this.is_super_admin || this.current_sp_roles.includes('NUMBER_MANAGER')
        },

        has_role_sim_manager() {
            return (this.is_super_admin || this.current_sp_roles.includes('NUMBER_MANAGER')) && (this.has_sp_role_prepay || this.has_sp_role_postpay || this.has_sp_role_fwa)
        },

        has_role_voucher_admin() {
            return this.is_super_admin || this.current_sp_roles.includes('LOYALTY_MANAGEMENT') || this.current_sp_roles.includes('VOUCHER_ADMIN')
        },

        has_role_voucher_partner() {
            return this.is_super_admin || this.current_sp_roles.includes('VOUCHER_PARTNER')
        },

        has_role_matrixx() {
            return this.is_super_admin || this.current_sp_roles.includes('SVC_MATRIXX')
        },

        has_role_domains() {
            return this.is_super_admin || this.current_sp_roles.includes('DOMAINS')
        },

        has_role_tv() {
            return this.is_super_admin || this.current_sp_roles.includes('SVC_TV')
        },

        has_role_radius() {
            return this.is_super_admin || this.current_sp_roles.includes('SVC_RADIUS')
        },

        has_role_bulkprequal() {
            return this.is_super_admin || this.current_sp_roles.includes('BULK_PRE_QUAL')
        },

        has_role_asap_any() {
            return this.has_role_asap_read || this.has_role_asap_write
        },

        has_role_asap_read() {
            return this.is_super_admin || this.current_sp_roles.includes('ASAP.READ')
        },

        has_role_asap_write() {
            return this.is_super_admin || this.current_sp_roles.includes('ASAP.WRITE')
        },

        has_role_sms_gateway() {
            return this.is_super_admin || this.current_sp_roles.includes('SMS_GATEWAY')
        },

        has_role_sip_trunking() {
            return this.is_super_admin || this.current_sp_roles.includes('SIP_TRUNKING')
        },

        categories() {
            const available_modules = {}

            let modules = {}
            if (this.has_role_customers) {
                modules.CUSTOMERS = {
                        Name:  'CUSTOMERS',
                        Type:  'GENERAL',
                        Title: 'Customers',
                }
            }
            for (const thismodule in this.modules_sp) {
                if (thismodule == 'BULK_PRE_QUAL' && (!this.has_role_bulkprequal)) {
                    continue
                }
                if (thismodule == 'DOMAINS' && (!this.has_role_domains || !this.is_prerelease_mode)) {
                    continue
                }
                if (thismodule == 'SVC_RADIUS' && !this.is_prerelease_mode) {
                    continue
                }
                if (thismodule == 'SVC_TV' && !this.is_prerelease_mode) {
                    continue
                }
                if (thismodule == 'SVC_MATRIXX' && !this.is_prerelease_mode) {
                    continue
                }
                if (thismodule == 'PSTN_MANAGER' && !this.is_prerelease_mode) {
                    continue
                }
                if (thismodule == 'IP_MANAGER' && !this.is_prerelease_mode) {
                    continue
                }
                if (thismodule == 'LOYALTY_MANAGEMENT' && (!this.is_prerelease_mode)) {
                    continue
                }
                modules[thismodule] = this.modules_sp[thismodule]
            }

            for (const key in modules) {
                if (MODULES[key]) {
                    const module = JSON.parse(JSON.stringify(MODULES[key]))

                    if (module.sub_menu && Array.isArray(module.sub_menu)) {
                        module.title = modules[key].Title

                        module.sub_menu = module.sub_menu.filter(menu =>
                            Array.isArray(menu.restrictions)
                                ? menu.restrictions.findIndex(restriction => this[restriction]) > -1
                                : true
                        )

                        /**
                         * It makes no sense to display a module in which an empty menu
                         */
                        if (module.sub_menu.length) {
                            available_modules[key] = module
                        }
                    } else {
                        module.link.title = modules[key].Title

                        available_modules[key] = module
                    }
                }
            }

            const categories = {}

            for (const key in available_modules) {
                const type = modules[key].Type

                if (!(type in categories)) {
                    categories[type] = {
                        title: CONFIG[type],
                        modules: {}
                    }
                }

                categories[type].modules[key] = available_modules[key]
            }

            return categories
        },

        active_category() {
            const route_name = this.$route.name
            let active_category = null

            labelCancelLoops:
            for (const category_name in this.categories) {
                const category = this.categories[category_name]

                for (const module_name in category.modules) {
                    const module = category.modules[module_name]

                    const css_class = module.class

                    if (Array.isArray(module.route_names)) {
                        for (let i = 0, len = module.route_names.length; i < len; i++) {
                            if (route_name == module.route_names[i]) {
                                active_category = css_class
                                break labelCancelLoops
                            }
                        }
                    }

                    if (Array.isArray(module.sub_menu)) {
                        for (let i = 0, len = module.sub_menu.length; i < len; i++) {
                            if (route_name == module.sub_menu[i].to.name) {
                                active_category = css_class
                                break labelCancelLoops
                            }
                        }
                    } else if (module.link) {
                        if (route_name == module.link.to.name) {
                            active_category = css_class
                            break labelCancelLoops
                        }
                    }
                }
            }

            return active_category
        },

        is_android() {
            return isAndroid()
        },
    },

    methods: {
        isActive(css_class) {
            return css_class == this.active_category
                || css_class == this.active_sub_menu
        },

        toggleSubMenu(submenu) {
            if (this.active_sub_menu == submenu) {
                this.active_sub_menu = null
            } else {
                this.active_sub_menu = submenu
            }
        },

        close() {
            this.$emit('toggle', false)
        },

        // Check iOS version with Javascript
        checkiOSVersion() {
            const agent = window.navigator.userAgent
            const start = agent.indexOf( 'OS ' )

            if( ( agent.indexOf( 'iPhone' ) > -1 || agent.indexOf( 'iPad' ) > -1 ) && start > -1 ) {
                return window.Number( agent.substr( start + 3, 3 ).replace( '_', '.' ) )
            }

            return 0
        },

        renderIndicators() {
            const indicator_height = 40 // This value is taken from SCSS variable $indicator-height
            const scroll = this.$refs.scroll

            const scroll_top = scroll.scrollTop
            const scroll_bottom = scroll.scrollHeight - scroll.scrollTop - scroll.clientHeight

            this.show_top_indicator = scroll_top > indicator_height
            this.show_bottom_indicator = scroll_bottom > indicator_height
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.close()
            }
        },

        scrollTop() {
            this.$refs.scroll.scrollTo(0, 0)
        },
        scrollBottom() {
            this.$refs.scroll.scrollTo(0, this.$refs.scroll.scrollHeight - this.$refs.scroll.clientHeight)
        },
    },

    mounted() {
        // iOS 13.3.1
        this.is_ios_13 = this.checkiOSVersion() == 13
        this.renderIndicators()

        const scroll = this.$refs.scroll
        scroll.addEventListener('scroll', this.renderIndicators)

        window.addEventListener('resize', this.renderIndicators)

        if (this.showed) {
            document.addEventListener('click', this.handleClickOutside)
        }
    },

    beforeDestroy() {
        const scroll = this.$refs.scroll
        scroll.removeEventListener('scroll', this.renderIndicators)

        window.removeEventListener('resize', this.renderIndicators)

        document.removeEventListener('click', this.handleClickOutside)
    },

    watch: {
        $route(to, from) {
            this.active_sub_menu = null
        },

        modules_sp: {
            deep: true,

            handler() {
                this.$nextTick(() => {
                    this.renderIndicators()
                })
            }
        },

        showed() {
            if (this.showed) {
                this.$nextTick(() => {
                    this.renderIndicators()
                })

                /**
                 * Method "addEventListener" fires up earlier than "click" event bubbling completely
                 */

                const delay_in_ms = 0

                setTimeout(() => {
                    document.addEventListener('click', this.handleClickOutside)
                }, delay_in_ms)

            } else {
                document.removeEventListener('click', this.handleClickOutside)
            }
        },
    }
}
</script>

<style lang="scss">
.app-navigation-bar {
    position: fixed;

    top: 0;
    left: 0;
    bottom: 0;
    z-index: $z-index-app-navigation-bar;

    width: $navigation-bar-width;
    max-width: 100%;

    background-color: var(--color-component-bg-primary);

    border-right: $navigation-bar-border-width solid var(--color-nav-bar-border-right);

    transition: width $transition-duration-primary;

    &.is-android {
        /*
            На Galaxy S8 (Android 9) воспроизводился баг, при котором
            в компоненте app-navigation-bar в самом низу на мгновение была видна основная страница при скролле.
            Сделал решение, зависящее от платформы, потому что на iOS в Google Chrome системная панель перекрывала часть
            компонента app-navigation-bar (видимо не корректно расчитывалось значение 100vh)
        */

        &::after {
            position: absolute;

            top: 0;
            left: 0;
            right: 0;

            z-index: -1;

            display: block;
            height: 100vh;

            background-color: var(--color-component-bg-primary);

            content: '';
        }
    }

    .app-logo {
        justify-content: center;
        padding: 0 16px;

        .wide {
            display: none;
        }
    }

    .navigation-box {
        $app-logo-height: 96px;
        $scrollbar-width: 6px;
        $indicator-height: 40px;

        position: relative;

        height: calc(100% - #{ $app-logo-height });

        overflow-x: hidden;

        .indicator {
            position: absolute;
            left: 0;
            right: $scrollbar-width;

            z-index: 1;

            height: $indicator-height;

            cursor: pointer;

            &:hover {
                opacity: .8;
            }

            .bg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                opacity: 0.8;
            }

            &.top {
                top: 0;

                .bg {
                    box-shadow: var(--nav-bar-indicator-top-shadow);
                    background-image: linear-gradient(
                        to bottom,
                        rgba(var(--color-component-bg-primary-rgb), 0.2),
                        var(--color-component-bg-primary)
                    );
                }

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-18px, -18px);

                    display: block;
                    width: 36px;
                    height: 36px;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;

                        @include icon($icon-chevron-top, 36px);
                    }
                }
            }

            &.bottom {
                bottom: 0;

                .bg {
                    box-shadow: var(--nav-bar-indicator-bottom-shadow);
                    background-image: linear-gradient(
                        to bottom,
                        var(--color-component-bg-primary),
                        rgba(var(--color-component-bg-primary-rgb), 0.2)
                    );
                }

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-18px, -18px);

                    display: block;
                    width: 36px;
                    height: 36px;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;

                        @include icon($icon-chevron-bottom, 36px);
                    }
                }
            }

            &.is-ios-13 {
                right: 0;
            }
        }

        .scroll {
            height: 100%;

            @include webkit-scrollbar-vertical(
                none,
                var(--color-nav-bar-scrollbar-bg),
                var(--color-nav-bar-scrollbar-thumb-bg),
                $scrollbar-width
            );
        }

        .category {
            // padding: 32px 7px 32px 3px; // $scrollbar-width => 4px
            padding: 32px 5px 32px 5px;    // $scrollbar-width => 6px

            box-shadow: 0 1px 0 var(--color-nav-bar-divider);

            &:last-child {
                box-shadow: none;
            }

            .name {
                display: block;
                text-align: center;

                margin-bottom: 24px;
                padding-left: 0px;
                width: 100%;

                font-size: 14px;
                font-weight: bold;
                line-height: 24px;

                text-transform: uppercase;

                transition: $transition-duration-primary;

                @include text-overflow();
            }

            .menu-box {
                padding: 0 11px;
            }
        }

        .menu {
            width: 100%;
            margin: 0 0 24px;

            .toggle {
                position: relative;

                display: flex;
                align-items: center;

                width: 100%;
                height: 48px;

                padding-left: 14px;

                border-radius: $border-radius-primary;
                cursor: pointer;
                overflow: hidden;
                transition: padding $transition-duration-primary;

                .title {
                    margin-left: 10px;
                    font-size: 18px;
                    line-height: 1.78;
                    color: var(--color-nav-bar-text);
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity $transition-duration-primary;
                    user-select: none;
                    @include text-overflow();
                }

                .icon {
                    flex-shrink: 0;
                    width: 24px;
                    height: 24px;
                    color: var(--color-nav-bar-icon);

                    @include icon-before($icon-nav-default);
                }

                &:hover {
                    box-shadow: var(--box-shadow-primary);

                    .icon {
                        color: var(--color-nav-bar-icon-hover);
                    }
                }
            }

            .sub-menu {
                display: none;
                flex-direction: column;
                padding: 7px 0;
                background-color: var(--color-nav-bar-sub-menu-bg);
                border-radius: 0 0 $border-radius-primary $border-radius-primary;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 38px;
                    font-size: 16px;
                    line-height: 1.5;
                    color: var(--color-nav-bar-text);
                    border-left: 2px solid transparent;

                    .icon {
                        position: relative;
                        flex-shrink: 0;
                        width: 46px;
                        height: 100%;

                        i {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            display: block;
                            width: 24px;
                            height: 24px;
                            color: var(--color-nav-bar-icon-hover);

                            @include icon-before($icon-nav-default);
                        }
                    }

                    .link {
                        width: calc(100% - 46px);

                        opacity: 0;
                        visibility: hidden;

                        transition: opacity $transition-duration-primary;

                        user-select: none;

                        @include text-overflow();
                    }

                    &:hover {
                        background-color: var(--color-nav-bar-link-hover);
                        border-left: 2px solid var(--color-nav-bar-icon-hover);
                    }

                    &.router-link-active {
                        background-color: var(--color-nav-bar-link-active);
                        border-color: var(--color-nav-bar-icon-active);

                        .icon {
                            i {
                                color: var(--color-nav-bar-icon-active);
                            }
                        }
                    }

                    @each $action in (
                        'add-service',
                        'services',
                        'orders',
                        'products',
                        'sessions',
                        'usage',
                        'config',
                        'households',
                        'devices',
                        'entitlements',
                        'templates',
                        'configuration',
                        'sp-groups',
                        'customer-groups',
                        'subscriptions',
                        'sip-trunks',
                        'channels',
                        'routing',
                        'tools',
                        'domain-list',
                        'register-domain',
                        'transfer-domain',
                        'default-settings',
                        'query',
                        'allocate',
                        'vrfs',
                        'pools',
                        'pstn-lookup',
                        'pstn-allocate',
                        'smsc-entries',
                        'ranges',
                        'sim-ranges',
                        'allocations',
                        'search-voucher',
                        'asap-configuration',
                        'asap-workflows',
                        'prepay-mobile-preactivation',
                        'sms-gateway-send-message',
                        'sms-gateway-queues',
                        'number-porting-number-ports',
                    ) {
                        &.#{ $action } {
                            .icon {
                                i {
                                    @include icon-before-code(map-get($icons-nav, $action));
                                }
                            }
                        }

                        &:hover,
                        &.router-link-active {
                            &.#{ $action } {
                                .icon {
                                    i {
                                        @include icon-before-code(map-get($icons-nav-active, $action));
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.opened {
                .toggle {
                    box-shadow: var(--box-shadow-primary);

                    .icon {
                        color: var(--color-nav-bar-icon-hover);
                    }
                }

                .sub-menu {
                    display: flex;
                }

            }

            @each $group in (
                'customers',
                'mobile',
                'bulk-pre-qual',
                'fwa',
                'fibre',
                'radius',
                'tv',
                'digital-comms',
                'number-porting',
                'matrixx',
                'sip-trunking',
                'domains',
                'ip-manager',
                'pstn-manager',
                'smsc',
                'number-manager',
                'loyalty-management',
                'asap',
                'prepay-mobile',
                'sms-gateway',
                'service-inventory',
                'service-orders',
            ) {
                &.#{ $group } {
                    .toggle {
                        .icon {
                            @include icon-before-code(map-get($icons-nav, $group));
                        }
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &:hover,
    &.showed {
        width: $navigation-bar-opened-width;

        .app-logo {
            justify-content: flex-start;
            padding: 0 24px;

            .wide {
                display: block;
            }

            .small {
                display: none;
            }
        }

        .navigation-box {
            .category {
                padding: 32px 12px;

                .name {
                    text-align: left;
                    padding-left: 28px;
                    font-size: 16px;
                }

                .menu-box {
                    padding: 0 12px;
                }
            }

            .menu {
                .toggle {
                    padding-left: 14px;

                    .title {
                        opacity: 1;
                        visibility: visible;
                    }

                }

                .sub-menu {
                    a {
                        .link {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-navigation-bar {
        &:not(.showed) {
            display: none;
        }

        &.showed {
            width: $navigation-bar-opened-width-tablet;

            .app-logo {
                justify-content: space-between;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-navigation-bar {
        &.showed {
            .app-logo {
                padding: 0 16px;
            }
        }

        .navigation-box {
            $app-logo-height: 64px;

            height: calc(100% - #{ $app-logo-height });

            .menu {
                .toggle {
                    .title {
                        font-size: 16px;
                    }
                }

                .sub-menu {
                    a {
                        .icon {
                            i {
                                margin-left: 8px;
                            }
                        }

                        .link {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>