<template>
    <div class="app-logo">
        <router-link :to="{ name: 'main-dashboard' }">
            <template v-if="current_spid">
                <img class="wide"  :src="wide">
                <img class="small" :src="small">
            </template>
        </router-link>

        <button class="btn btn-close" @click="close"></button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            wide: null,
            small: null,
        }
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'current_sp',
        ]),
    },

    created() {
        if (this.current_spid) {
            this.loadLogos()
        }
    },

    methods: {
        loadLogos() {
            const sizes = {
                wide:  'LogoWideURL',
                small: 'LogoIconURL',
            }

            for (const size in sizes) {
                const img = new Image()

                img.onload = () => {
                    this[size] = this.current_sp[sizes[size]]
                }

                img.onerror = () => {
                    import(`./img/${ size }.png`)
                        .then(src => {
                            this[size] = src.default
                        })
                        .catch(error => {
                            this[size] = null
                        })
                }

                img.src = this.current_sp[sizes[size]]
            }
        },

        close() {
            this.$emit('close')
        },
    },

    watch: {
        current_spid() {
            if (this.current_spid) {
                this.loadLogos()
            }
        },
    },
}
</script>

<style lang="scss">
.app-logo {
    display: flex;
    align-items: center;
    height: 96px;
    box-shadow: 0 1px 0 var(--color-nav-bar-divider);

    a {
        max-width: 100%;
        overflow: hidden;
    }

    .wide {
        display: block;

        width: auto;
        max-width: 192px;

        height: auto;
        max-height: 48px;
    }

    .small {
        display: block;

        width: auto;
        max-width: 48px;

        height: auto;
        max-height: 48px;
    }

    .btn-close {
        flex-shrink: 0;
        display: none;

        width: 32px;
        height: 32px;

        &:after {
            width: 32px;
            height: 32px;
            font-size: 32px;
        }
    }
}

@media (max-width: $tablet-size) {
    .app-logo {
        .btn-close {
            display: flex;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-logo {
        height: 64px;
    }
}
</style>