<template>
    <div class="app-search-v2" :class="{ focused, typed }">
        <div class="input" @click="focus">
            <div class="magnifier"><i></i></div>

            <input
                v-model="value"

                type="text"
                placeholder="Search"

                ref="input"

                @input="onInput"
                @keyup.enter="onEnter"

                @focus="onFocus"
                @blur="onBlur"
            >

            <button class="btn btn-close" v-if="typed" @click.stop="clear"></button>
        </div>

        <div class="options" :class="{ show: (opened || focused) && has_options }" ref="scroll">
            <div class="scroll">
                <div class="option" v-for="option in options" :key="option.key">
                    <div class="title" @click="onSelect(option)">
                        <span>{{ option.value }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

import { mapGetters } from 'vuex'

export default {
    props: {
        delay: { type: Number, default: 0 }, // The number of milliseconds to wait
    },

    data() {
        return {
            simple_bar: null,

            value: '',
            options: [],

            opened: false,

            debounce: null,

            focused: false,
        }
    },

    mounted() {
        this.simple_bar = new SimpleBar(this.$refs.scroll, {
            autoHide: false
        })
    },

    methods: {
        focus() {
            this.$refs.input.focus()
        },

        onInput() {
            if (this.delay) {
                clearTimeout(this.debounce)

                this.debounce = setTimeout(() => {
                    this.search()
                }, this.delay)
            } else {
                this.search()
            }
        },

        onEnter() {
            if (this.delay) {
                clearTimeout(this.debounce)
            }

            this.search()
        },

        getServiceOrders() {
            const params = {
                SPID: this.current_spid,
                SmartSearch: this.value,

                // 'SearchOptions.PageSize': 10,
            }

            return this.$store.dispatch('api_service_order/FindServiceOrdersPaginated', params)
                .then(response => {
                    if (!response.ServiceOrders || !response.PageInfo) {
                        return Promise.reject(response)
                    }

                    const service_orders = response.ServiceOrders
                        .filter(order => order.CustomerUUID)
                        .map(order => ({
                            key: order.UUID,
                            value: `${ order.UUID } (${ order.CustomerUUID })`,

                            CustomerUUID: order.CustomerUUID,
                        }))

                    return Promise.resolve(service_orders)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },

        getProductInstances() {
            const params = {
                SPID: this.current_spid,
                SmartSearch: this.value,
                // 'SearchOptions.PageSize': 10,
            }
            // const params = {
            //     SPID: this.current_spid,
            //     SearchText: this.value,
            //     RefreshCache: false,
            // }

            // return this.$store.dispatch('api_product_instance/getCachedProductInstancesBySearch', params)
            return this.$store.dispatch('api_product_instance/FindProductInstancesWithDetailsPaginated', params)
                .then(response => {
                    if (!response.ProductInstanceWithDetails) {
                        return Promise.resolve([])
                    }

                    if (response.ProductInstanceWithDetails) {
                        for (let pi = 0; pi < response.ProductInstanceWithDetails.length; pi++) {
                            if (response.ProductInstanceWithDetails[pi].State && response.ProductInstanceWithDetails[pi].State.State) {
                                response.ProductInstanceWithDetails[pi].StateText = ` / ${ response.ProductInstanceWithDetails[pi].State.State }`
                            } else {
                                response.ProductInstanceWithDetails[pi].StateText = ""
                            }
                            response.ProductInstanceWithDetails[pi].MSISDN = ""
                            response.ProductInstanceWithDetails[pi].IMSI = ""
                            if (!response.ProductInstanceWithDetails[pi].Characteristics) {
                                continue
                            }
                            for (let pic = 0; pic < response.ProductInstanceWithDetails[pi].Characteristics.length; pic++) {
                                if (response.ProductInstanceWithDetails[pi].Characteristics[pic].Name == "cellular.MSISDN") {
                                    response.ProductInstanceWithDetails[pi].MSISDN = response.ProductInstanceWithDetails[pi].Characteristics[pic].Value
                                }
                                if (response.ProductInstanceWithDetails[pi].Characteristics[pic].Name == "sim.IMSI") {
                                    response.ProductInstanceWithDetails[pi].IMSI = response.ProductInstanceWithDetails[pi].Characteristics[pic].Value
                                }
                                if (response.ProductInstanceWithDetails[pi].Characteristics[pic].Name == "ufb.Address") {
                                    response.ProductInstanceWithDetails[pi].Address = response.ProductInstanceWithDetails[pi].Characteristics[pic].Value
                                }
                            }

                    //         // response.ProductInstanceWithDetails[i]
                        }
                    }

                    const product_instances = response.ProductInstanceWithDetails
                        // .filter(pi => pi.CustomerUUID)
                        .map(pi => ({
                            key: pi.Instance.UUID,
                            value: pi.MSISDN  ? `[${pi.Instance.ProductType}] MSISDN: ${ pi.MSISDN } IMSI: ${ pi.IMSI }${ pi.StateText }` :
                                   pi.Address ? `[${pi.Instance.ProductType}${ pi.StateText }] ${ pi.Address }` : `[${pi.Instance.ProductType}] UUID: ${ pi.Instance.UUID }`,

                            CustomerUUID: pi.Instance.CustomerUUID,
                        }))

                    return Promise.resolve(product_instances)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },

        getCustomers() {
            const params = {
                SPID: this.current_spid,
                SearchText: this.value,
                RefreshCache: false,
            }

            return this.$store.dispatch('api_customer/getCachedCustomersBySearch', params)
                .then(response => {
                    if (!response.Customers || !response.PageInfo) {
                        return Promise.reject(response)
                    }

                    const customers = response.Customers.map(customer => {
                        let val = ''

                        if (customer.FirstName) {
                            val = `${val} ${customer.FirstName}`
                            val = val.trim()
                        }

                        if (customer.LastName) {
                            val = `${val} ${customer.LastName}`
                            val = val.trim()
                        }

                        if (customer.Email) {
                            val = `${val} (${customer.Email})`
                            val = val.trim()
                        }

                        if (customer.ExternalRef1) {
                            val = `${val} [${customer.ExternalRef1}]`
                            val = val.trim()
                        }

                        return {
                            key: customer.UUID,
                            value: val,

                            CustomerUUID: customer.UUID,
                        }
                    })

                    return Promise.resolve(customers)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },

        search() {
            if (this.value.length >= 3) {
                const promises = [
                    this.getCustomers(),
                    this.getProductInstances(),
                ]

                Promise.all(promises)
                    .then(([customers = [], product_instances = []]) => {
                        this.options = [
                            ...customers,
                            ...product_instances,
                        ]

                        if (!this.opened) {
                            this.open()
                        }
                    })
                    .catch(error => {
                        this.$store.dispatch('addToast', {
                            message: errMessage(error),
                            type: 'error',
                            delay: 5000,
                        })
                    })
            } else {
                this.options = []
            }
        },

        onSelect(option) {
            this.close()

            this.$router.push({ name: 'customer-details', params: { uuid: option.CustomerUUID } })
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.close(true)
            }
        },

        open() {
            this.opened = true

            document.addEventListener('click', this.handleClickOutside)
        },

        close(click_outside = false) {
            this.opened = false

            if (!click_outside) {
                this.value = ''
                this.options = []
            }

            document.removeEventListener('click', this.handleClickOutside)
        },

        onFocus() {
            this.$emit('focus')
            this.focused = true
        },
        onBlur() {
            this.$emit('blur', this.typed)
            this.focused = false
        },

        clear() {
            this.value = ''
            this.focused = false

            this.close()
        },
    },
    
    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        has_options() {
            return this.options.length > 0
        },

        typed() {
            return this.value.length > 0
        },
    },
}
</script>

<style lang="scss">
.app-search-v2 {
    $option-height: 32px + 8px * 2;

    position: relative;
    display: flex;
    width: 100%;
    max-width: 570px;
    height: 48px;

    .input {
        position: relative;
        display: flex;
        width: 100%;

        border: 1px solid var(--color-search-border);
        border-radius: $border-radius-primary;
        background-color: var(--color-search-bg);

        .magnifier {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 100%;

            i {
                width: 26px;
                height: 26px;

                color: var(--color-icon-search);

                @include icon-before($icon-search, 26px);
            }
        }

        input {
            width: 100%;
            padding-left: 48px;
            padding-right: 16px;
            font-size: 18px;
            line-height: 1.33;
            color: var(--color-text-default);

            &::-webkit-input-placeholder {
                color: var(--color-input-placeholder);
                opacity: 1;
                transition: opacity $transition-duration-primary;
            }

            &::-moz-placeholder {
                color: var(--color-input-placeholder);
                opacity: 1;
                transition: opacity $transition-duration-primary;
            }

            &:-ms-input-placeholder {
                color: var(--color-input-placeholder);
                opacity: 1;
                transition: opacity $transition-duration-primary;
            }

            &:-moz-placeholder {
                color: var(--color-input-placeholder);
                opacity: 1;
                transition: opacity $transition-duration-primary;
            }

            &:focus {
                &::-webkit-input-placeholder {
                    opacity: 0;
                }

                &::-moz-placeholder {
                    opacity: 0;
                }

                &:-ms-input-placeholder {
                    opacity: 0;
                }

                &:-moz-placeholder {
                    opacity: 0;
                }
            }
        }

        .btn-close {
            display: flex;

            flex-shrink: 0;
            width: 32px;
            height: 100%;
        }

        &:hover {
            border-color: var(--color-search-border-hover);
        }
    }

    &.focused,
    &.typed {
        .input {
            border-color: var(--color-search-border-active);
        }
    }

    .simplebar-track {
        &.simplebar-vertical {
            top: 15px;
            right: 10px;
            bottom: 15px;
            width: 4px;
            background-color: var(--color-scrollbar-bg);
            border-radius: 2px;

            .simplebar-scrollbar {
                &::before {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: var(--color-scrollbar-thumb-bg);
                    border-radius: 2px;
                    opacity: 1;
                }
            }
        }
    }

    .options {
        position: absolute;

        top: calc(100% + 4px);
        left: 0;
        right: 0;

        z-index: $z-index-dropdown;

        display: flex;
        flex-direction: column;

        max-height: 3 * $option-height;

        background-color: var(--color-autocomplete-bg);
        box-shadow: var(--box-shadow-tertiary);
        border-radius: $border-radius-primary;

        // opacity: 0;
        // visibility: hidden;
        display: none;

        &.show {
            // opacity: 1;
            // visibility: visible;
            display: block;
        }

        .option {
            position: relative;
            display: flex;
            align-items: center;
            height: $option-height;

            .title {
                display: flex;
                align-items: center;
                width: 100%;
                height: 32px;
                padding: 0 24px;
                font-size: 18px;
                line-height: 1.33;
                color: var(--color-autocomplete-option);
                cursor: pointer;
                user-select: none;

                span {
                    @include text-overflow();
                }

                &:hover {
                    background-color: var(--color-autocomplete-option-hover-bg);
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-search-v2 {
        max-width: 450px;
    }
}

@media (max-width: $mobile-size) {
    .app-search-v2 {
        width: 32px;

        .input {
            input {
                width: 1px;
                opacity: .01;
            }

            .magnifier {
                width: 32px;
            }
        }

        &.focused,
        &.typed {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: $z-index-app-search-v2;

            .input {
                input {
                    width: 100%;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 0 0 0 32px;
                    opacity: 1;
                }
            }
        }
    }
}
</style>