<template>
    <app-dialog-info
        class="app-dialog-jwt-expiry"

        :value="value"

        :max-width="maxWidth"

        @closes="onCloses"
    >
        <template #head>
            <h2 class="heading">You are about to be automatically logged out</h2>
        </template>

        <template #body>
            <app-loader v-if="loading"></app-loader>

            <div class="wrap">
                <app-error v-model="errors.show" :message="errors.message"></app-error>

                <div class="content">
                    <p>Please click below to stay logged in</p>
                </div>

                <div class="btns">
                    <button class="btn btn-primary" @click="refreshJWT">I'm still here</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:    { required: true                       },
        maxWidth: { type: [Number, String], default: 660 },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
    },

    data() {
        return {
            loading: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    methods: {
        onCloses() {
            this.$store.dispatch('closeJWTExpiryDialog').then(() => {}).catch(() => {})
        },

        refreshJWT() {
            this.loading = true

            this.$store.dispatch('refreshJWT')
                .then(() => {
                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false

                    this.$store.dispatch('closeJWTExpiryDialog').then(() => {}).catch(() => {})
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.loading = false
                })
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-jwt-expiry {
    z-index: $z-index-dialog-upmost;

    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }

        p {
            font-weight: normal;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-jwt-expiry {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>