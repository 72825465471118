<template>
    <div class="app-header">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-white btn-navigation" @click="toggleNavigation"><i></i></button>

                    <!-- <app-search
                        v-model="search"

                        :options="search_options"
                        :debounce="1000"

                        @change="onSearchChange"
                    /> -->

                    <app-search-v2 :delay="250" />

                    <app-account />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import appSearch from '@/components/app-search'

import appSearchV2 from '@/components/app-search-v2'
import appAccount from '@/components/app-account'

export default {
    components: {
        // appSearch,
        appSearchV2,
        appAccount,
    },

    data() {
        return {
            search: {
                word: '',
                option: 'customers',
            },
        }
    },

    methods: {
        onSearchChange() {
            this.$emit('search', this.search)
        },

        toggleNavigation() {
            this.$emit('toggle-navigation', true)
        },
    },

    computed: {
        search_options() {
            return this.$route.name == 'main-dashboard'
                ? [
                    { key: 'customers', title: 'by Name' },
                    { key: 'fwa',       title: 'by ID'   },
                ]
                : false
        },
    },
}
</script>

<style lang="scss">
.app-header {
    flex-shrink: 0;

    .container {
        .row {
            .col-12 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0;

                &>* {
                    margin: 0 $container-padding / 2;
                }
            }
        }
    }

    .btn-navigation {
        display: none;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 48px;

        i {
            width: 24px;
            height: 24px;

            color: var(--color-icon-calm);

            @include icon-before($icon-menu);
        }
    }

    .app-account-box {
        flex-shrink: 0;
        max-width: 50%;
    }
}

@media (max-width: $tablet-size) {
    .app-header {
        .btn-navigation {
            display: flex;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-header {
        background: var(--color-component-bg-primary);

        .container {
            .row {
                .col-12 {
                    position: relative;
                    
                    &>* {
                        margin: 0;
                    }

                    .btn-navigation {
                        margin-left: 6px;
                    }

                    .app-search-v2 {
                        margin-left: auto;
                    }
                }
            }
        }
    }
}
</style>