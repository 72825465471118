<template>
    <app-dialog-info
        class="dialog-change-password"
        v-if="show"
        v-model="show"
        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Change password</h2>
        </template>

        <template #body>
            <app-loader v-if="loading"></app-loader>

            <div class="form">
                <app-error v-model="errors.save"></app-error>

                <app-input
                    v-model="current_password"

                    type="password"

                    label="Current password"

                    :error="errors.current_password"

                    :with-toggle-password-visibility="true"
                />

                <app-input
                    v-model="new_password"

                    type="password"

                    label="New password"

                    :error="errors.new_password"

                    :password-helper="true"
                    :with-toggle-password-visibility="true"

                    ref="new-password"

                    @toggle-password-visibility="onTogglePasswordVisibility"
                />

                <app-input
                    v-model="confirm_new_password"

                    type="password"

                    label="Confirm new password"

                    :error="errors.confirm_new_password"

                    :with-toggle-password-visibility="true"

                    ref="confirm-new-password"

                    @toggle-password-visibility="onTogglePasswordVisibility"
                />

                <button class="btn btn-primary btn-save" @click="save">Save</button>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'

import errMessage from '@/helpers/errMessage'

import UserDetailsValidator from '@/validators/user-details-validator'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appInput,
    },

    data() {
        return {
            current_password: '',
            new_password: '',
            confirm_new_password: '',

            loading: false,

            errors: {},
        }
    },

    methods: {
        onTogglePasswordVisibility(password_visibility) {
            this.$refs['new-password'].setPasswordVisibility(password_visibility)
            this.$refs['confirm-new-password'].setPasswordVisibility(password_visibility)
        },

        validation() {
            let is_valid = true

            this.errors = {}

            const fields = {
                current_password:     { rule: 'pass',    message: 'Please, enter current password'             },
                new_password:         { rule: 'passnew', message: 'Please, enter new password'                 },
                confirm_new_password: { rule: 'passnew', message: 'Please, enter confirmation of new password' },
            }

            for (const key in fields) {
                if (UserDetailsValidator.isRuleExists(fields[key].rule)) {
                    if (UserDetailsValidator.isInvalid(fields[key].rule, this[key], fields[key].message)) {
                        this.errors[key] = UserDetailsValidator.getError()
                        is_valid = false
                    }
                }
            }

            if (!this.errors.confirm_new_password) {
                if (this.new_password != this.confirm_new_password) {
                    this.errors.confirm_new_password = 'New password and confirmation password do not match'
                    is_valid = false
                }
            }

            return is_valid
        },

        save() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    UUID: this.id_uuid,
                    SPID: this.current_spid,
                    OldPassword: this.current_password,
                    NewPassword: this.new_password,
                }

                this.$store.dispatch('api_auth/ResetPasswordStep3LocalUser', params)
                    .then(() => {
                        this.close()
                        this.loading = false

                        this.$store.dispatch('addToast', {
                            message: 'Your password was successfully changed',
                            type: 'success',
                            delay: 5000,
                        })
                    })
                    .catch(error => {
                        this.errors.save = errMessage(error)
                        this.loading = false
                    })
            }
        },

        close() {
            this.$emit('close')
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'id_auth_id',
            'id_uuid',
            'id_spid',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.dialog-change-password {
    .app-dialog-body {
        .form {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 100%;

            .app-error {
                margin-bottom: 24px;
            }

            .app-input {
                margin-bottom: 30px;
            }

            .btn-save {
                max-width: 210px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.dialog-change-password {
        .app-dialog-body {
            h2 {
                margin-bottom: 40px;
            }

            .form {
                .btn-save {
                    max-width: 100%;
                }
            }
        }
    }
}
</style>