<template>
    <div class="layout" :class="{ 'sidebar-opened': is_sidebar_opened }" :style="branding">
        <app-loader v-if="loading"></app-loader>

        <app-navigation-bar :showed="showed_navigation" @toggle="onToggleNavigation" />

        <div class="main">
            <div class="inner-container">
                <app-header
                    @search="onSearch"
                    @toggle-navigation="onToggleNavigation"
                />

                <router-view class="main-body"
                    :search="search"

                    @open-sidebar="openSidebar"
                    @close-sidebar="closeSidebar"

                    @apply-branding="applyBranding"

                    ref="body"
                ></router-view>
            </div>
        </div>

        <app-dialog-jwt-expiry
            v-if="show_jwt_expiry_dialog"
            :value="show_jwt_expiry_dialog"
        ></app-dialog-jwt-expiry>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appHeader from '@/components/app-header'
import appNavigationBar from '@/components/app-navigation-bar'
import appDialogJwtExpiry from '@/components/app-dialog-jwt-expiry'

export default {
    components: {
        appLoader,
        appHeader,
        appNavigationBar,
        appDialogJwtExpiry,
    },

    data() {
        return {
            search: {
                word: '',
                option: null,
            },

            is_sidebar_opened: false,

            showed_navigation: false,

            branding: null,
            branding_spid: null,
            
            loading: false,
            loading_counter: 0,
        }
    },

    created() {
        this.loadServiceProviderBranding(this.current_spid)
    },

    watch: {
        current_spid() {
            this.loading_counter++
            this.loadServiceProviderBranding(this.current_spid, true)
        },
    },

    methods: {
        onSearch(search) {
            if (this.$refs.body.onSearch && this.$refs.body.onSearch instanceof Function) {
                this.search = {...search}

                this.$refs.body.onSearch(this.search)
            }
        },

        openSidebar() {
            this.is_sidebar_opened = true

            if (this.showed_navigation) {
                this.showed_navigation = false
            }
        },

        closeSidebar() {
            this.is_sidebar_opened = false
        },

        onToggleNavigation(showed) {
            this.showed_navigation = showed === true || showed === false
                ? showed
                : !this.showed_navigation

            if (this.showed_navigation && this.is_sidebar_opened) {
                this.is_sidebar_opened = false
            }
        },



        applyBranding(branding) {
            this.branding = this.colorSchemeCompactToVariables(branding, true)
        },

        loadServiceProviderBranding(spid, is_spid_changed) {
            if (!spid) {
                spid = this.current_spid
            }

            if (spid && spid != this.branding_spid) {
                this.loading = this.loading_counter

                this.branding_spid = spid

                this.$store.dispatch('getBrandingBySPID', { spid }).then(branding => {
                    if (spid = this.branding_spid) {
                        this.applyBranding(branding)

                        this.checkPermissionsToRoute(this.$route).then(() => {
                            // console.log('checkPermissionsToRoute - SUCCESS')
                            if (is_spid_changed) {
                                this.reinitializeRoute()
                            }
                        }).catch(error => {
                            // console.log('checkPermissionsToRoute - ERROR', error)
                            this.$router.push(error.redirect || { name: 'main-dashboard', replace: true })
                        })
                    }
                }).catch(error => {
                    console.warn('Error getBrandingBySPID', spid, error)
                    console.dir(error)
                }).finally(() => {
                    if (this.loading == this.loading_counter) {
                        this.loading = false
                    }
                })
            }
        },

        checkPermissionsToRoute($route) {
            if (!$route) {
                $route = this.$route
            }
            
            return new Promise((resolve, reject) => {
                // console.group('checkPermissionsToRoute(route)')
                // console.log('$route', $route)

                const routePath = (routes, path = []) => {
                    // console.group(JSON.stringify(path))
                    // console.log('routes', routes)
                    // console.log('path', path)

                    const parents = [...path]
                    let is_find = false

                    for (let i = 0, len = routes.length; i < len; i++) {
                        // console.group(i)
                        // console.log('path', path)
                        // console.log('parents', parents)
                        if (routes[i].name == $route.name) {
                            path.push(i)
                            is_find = true
                            break
                        } else if (routes[i].children) {
                            var path_children = routePath(routes[i].children, [...parents, i])

                            if (path_children) {
                                path = path_children
                                is_find = true
                                break
                            } else {
                                // console.log('parents', parents)
                            }
                        }
                        // console.groupEnd()
                    }

                    if (!is_find) {
                        path = null
                        // console.log('!is_find')
                    }

                    // console.log('path', path ? [...path] : path)
                    // console.groupEnd()

                    return path
                }

                const routes = this.$router.options.routes
                const route_path = routePath(routes)
                const route_path_length = route_path.length

                // console.log('route_path', route_path)

                // console.group('permissions')

                const checkPermissions = (i) => {
                    // console.group(i)

                    if (i < route_path_length) {
                        let path = 0
                        let route = routes[route_path[path]]

                        while (path++ < i) {
                            // console.log('path', path, route_path[path])
                            route = 'children' in route ? route.children[route_path[path]] : route[route_path[path]]
                        }

                        // console.log('path -', route.path)
                        // console.log('route', route)

                        if ('beforeEnter' in route && typeof route.beforeEnter == 'function') {
                            // console.log('with permissions')

                            route.beforeEnter($route, $route, (redirect) => {
                                // console.group(`${ route.path } - checkingPermissions`)
                                // console.log('redirect', redirect)

                                if (redirect) {
                                    // console.warn(`this.$router.push( <redirect> )`)
                                    reject({ redirect })
                                    // this.$router.push(redirect)
                                } else {
                                    checkPermissions(++i)
                                }
                                // console.groupEnd()
                            })
                        } else {
                            // console.log('without permissions', typeof route.beforeEnter)
                            checkPermissions(++i)
                        }
                    } else {
                        resolve()
                    }
                    // console.groupEnd()
                }
                
                checkPermissions(0)
                // console.groupEnd()
            })
        },
        
        reinitializeRoute() {
            // console.group('reinitializeRoute() {')

            this.closeSidebar()
            
            const init_stack = []
            const initMethods = (view, stack) => {
                // console.group(view.$el.className)
                // console.log('$el', view.$el.className)
                // console.log('view', view)

                if ('init' in view && typeof view.init == 'function') {
                    stack.push(view.init)
                    // console.warn('<<< this.init')
                }

                if ('$children' in view) {
                    for (let i = 0, len = view.$children.length; i < len; i++) {
                        initMethods(view.$children[i], stack)
                    }
                }
                // console.groupEnd()
            }
            initMethods(this.$refs.body, init_stack)
            // console.warn('init_stack', init_stack)

            for (let i = 0, len = init_stack.length; i < len; i++) {
                init_stack[i](this.$route, this.$route)
                // console.log(`init_stack[${ i }]`, init_stack[i])
            }

            // console.groupEnd()
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',

            'colorSchemeCompactToVariables',

            'show_jwt_expiry_dialog',
        ]),
    },

    beforeRouteUpdate(to, from, next) {
        this.showed_navigation = false
        next()
    },
}
</script>

<style lang="scss">
.layout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    background-image: linear-gradient(to bottom, var(--color-layout-gradient-from), var(--color-layout-gradient-to));

    position: relative;

    overflow-x: hidden;

    &>.app-loader {
        z-index: $z-index-the-biggest;
    }

    .main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        min-height: 100%;

        padding: 24px $navigation-bar-width 0;

        transition: padding $transition-duration-primary;

        .inner-container {
            display: flex;
            flex-direction: column;

            flex-grow: 1;

            width: 100%;
            max-width: $container-width;

            height: 100%;

            margin: 0 auto;
        }

        .main-body {
            flex-grow: 1;
            margin-top: 56px;
        }
    }

    &.sidebar-opened {
        .main {
            padding-right: $sidebar-width;
        }
    }
}

@media (min-width: $tablet-size + 1px) {
    .layout {
        &.sidebar-opened {
            .base-layout {
                div {
                    &:first-child {
                        &.container,
                        .container {
                            & > .row {
                                & > .col-8 {
                                    flex-grow: 1;
                                    width: 100% / $grid-columns * 8;
                                    max-width: none;

                                    .app-pagination {
                                        margin-bottom: 30px;
                                    }
                                }

                                & > .col-4 {
                                    width: 100% / $grid-columns * 4;
                                    min-width: $tablet-size + 1px - $navigation-bar-width - $sidebar-width - $container-padding;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .layout {
        .main {
            padding: 24px 0 0;

            .main-body {
                margin-top: 48px;
            }
        }

        &.sidebar-opened {
            .main {
                padding: 24px 0 0;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .layout {
        .main {
            padding: 0;

            .main-body {
                margin-top: 32px;
            }
        }

        &.sidebar-opened {
            .main {
                padding: 0;
            }
        }
    }
}
</style>